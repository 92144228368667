import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ua/chapter_4/sub_2/slide1';
import WrapperMobile from 'src/slides/mobile/ua/chapter_4/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Ким був Михайло Тухачевський I Варшавська битва" lang="ua" description="Образ більшовицького Наполеона, який повинен був підпалити Європу." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Ким був Михайло Тухачевський I Варшавська битва" lang="ua" description="Образ більшовицького Наполеона, який повинен був підпалити Європу." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
